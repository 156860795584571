import React, { useState } from "react";
import "./contact.css";
import { IoCloseOutline } from "react-icons/io5";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
    recaptchaToken: "",
  });
  const [errors, setErrors] = useState({});
  const [contactModalVisible, setContactModalVisible] = useState(true);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleModalClose = () => {
    setContactModalVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear errors when user starts typing
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
    }
    if (formData.phoneNumber.trim() === "") {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!isValidPhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number format";
    }
    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (formData.message.trim() === "") {
      newErrors.message = "Message is required";
    }
    if (formData.recaptchaToken.trim() === "") {
      newErrors.recaptcha = "reCAPTCHA validation is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Prepare form data for submission
    const data = {
      name: formData.name,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      message: formData.message,
      recaptchaToken: formData.recaptchaToken,
    };

    try {
      // Send POST request to the backend
      const response = await fetch("./contact-action.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      // Check if request was successful based on the response value
      const responseData = await response.text();

      if (response.ok && responseData.includes("1")) {
        // Reset form data and set submission status to success
        setFormData({
          name: "",
          phoneNumber: "",
          email: "",
          message: "",
          recaptchaToken: "",
        });
        setSubmissionStatus("success");
      } else {
        // Set submission status to error
        setSubmissionStatus("error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Set submission status to error
      setSubmissionStatus("error");
    }
  };

  const handleRecaptchaChange = (token) => {
    setFormData({
      ...formData,
      recaptchaToken: token,
    });
    // Clear recaptcha error when recaptcha is validated
    setErrors({
      ...errors,
      recaptcha: "",
    });
  };

  const handleAnimationEnd = () => {
    if (!contactModalVisible) {
      closeModal();
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  return (
    <>
      <div className="contact-modal-overlay"></div>
      <div
        className={`contact-modal-content ${
          contactModalVisible ? "slide-in-bottom" : "slide-out-bottom"
        }`}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className="contact-modal-head">
          <p>Contact</p>
          <button onClick={handleModalClose}>
            <IoCloseOutline className="contact-modal-closeBtn" />
          </button>
        </div>
        {submissionStatus === "success" ? (
          <div className="submit-message success">
            Thank you for your response!
          </div>
        ) : submissionStatus === "error" ? (
          <div className="submit-message error">
            Failed to submit form data. Please try again later.
          </div>
        ) : (
          <form className="contact-modal-form" onSubmit={handleSubmit}>
            <div>
              <div className="input-error-container">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>

              <div className="input-error-container">
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                {errors.phoneNumber && (
                  <span className="error">{errors.phoneNumber}</span>
                )}
              </div>
            </div>
            <div>
              <div className="input-error-container">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
            </div>
            <div>
              <div className="input-error-container">
                <textarea
                  placeholder="Type your message here..."
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
                {errors.message && (
                  <span className="error">{errors.message}</span>
                )}
              </div>
            </div>

            <div>
              <div className="input-error-container">
                <ReCAPTCHA
                  sitekey="6Ldxe20UAAAAAD_17wcLirt0F7WmMb_Ixgoi3AYt"
                  onChange={handleRecaptchaChange}
                />
                {errors.recaptcha && (
                  <span className="error">{errors.recaptcha}</span>
                )}
              </div>
            </div>

            <div className="contact-submit-text-center">
              <input type="submit" value="Submit" />
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default Contact;
