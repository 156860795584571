import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const MetaTagsManager = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Dynamically update meta tags based on the current location
    // Example logic to update the title
    document.title = getTitle(location.pathname);
    // Update other meta tags as needed based on the URL
  }, [location]);

  // Function to generate title based on the current URL
  const getTitle = (pathname) => {
    switch (pathname) {
      case "/":
        return "Sir Sohan Roy | NRI Entrepreneur | Forbes Listed Indian Business Leader ";
      case "/about":
        return "About | Sohan Roy";
      case "/portfolio":
        return "Portfolio | Sohan Roy";
      case "/gallery":
        return "Gallery | Sohan Roy";
      case "/songs":
        return "Songs | Sohan Roy";
      case "/books":
        return "Books | Sohan Roy";
      case "/media":
        return "Media | Sohan Roy";
      case "/media/regional":
        return "Media Regional | Sohan Roy";
      case "/csr":
        return "CSR | Sohan Roy";
      default:
        return "Sir Sohan Roy | NRI Entrepreneur | Forbes Listed Indian Business Leader ";
    }
  };

  return <>{children}</>;
};

export default MetaTagsManager;
