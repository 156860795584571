import { useSpring } from "@react-spring/web";

const useFadeInAnimation = (delay = 0) => {
  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      duration: 300,
    },
    delay: delay,
  });

  return fadeIn;
};

export default useFadeInAnimation;
