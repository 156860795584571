import React from "react";
import "./mediaSectionCard.css";

import ArrowButton from "../ArrowButton/ArrowButton";

const MediaSectionCard = ({ mediaCardsData }) => {
  // Parse the date
  const formattedDate = new Date(mediaCardsData.date);

  // Format month and day
  const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
    formattedDate
  );
  const day = formattedDate.getDate().toString().padStart(2, "0");

  return (
    <div className="media-section-card">
      <div className="media-date-img-container">
        <div className="media-date-container">
          <h4 className="mediaTextColor">{month}</h4>
          <h3 className="mediaTextColor">{day}</h3>
        </div>
        <img
          src={mediaCardsData.localSource}
          alt="Media"
          className="media-img"
          width="140"
          height="160"
        />
      </div>

      <div className="media-details-container">
        <div className="media-date-container-2">
          <h4 className="mediaTextColor">{month}</h4>
          <h3 className="mediaTextColor">{day}</h3>
        </div>
        <div className="media-details">
          <h3 className="mediaTextColor">{mediaCardsData.title}</h3>
          <p className="mediaTextColor">{mediaCardsData.description}</p>
          {/* <ArrowButton
            label="View"
            textColor="#fffab9"
            arrowColor="#fffab9"
            arrowSize={`var(--arrowSize2)`}
            labelSize={`var(--labelSize2)`}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default MediaSectionCard;
