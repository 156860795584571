import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../assets/images/logo.svg";
import Modal from "../../../components/Modal/Modal";
import { useSpring, animated } from "@react-spring/web";
import useFadeInAnimation from "../../../hooks/animations/useFadeInAnimation ";
import { Contact } from "../../../components";
import "./navbar.css";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Navbar = () => {
  const fadeUp = useFadeInAnimation(200);
  const location = useLocation();
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  // Animation

  const galleryDropdownContentAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(-10px)" },
    to: {
      opacity: isGalleryOpen ? 1 : 0,
      transform: isGalleryOpen ? "translateY(0)" : "translateY(-10px)",
    },
    config: { mass: 1, tension: 280, friction: 25 },
  });

  const navLinksAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(-10px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { mass: 1, tension: 280, friction: 25 },
    delay: 500, // Add a delay of 500ms
  });

  const toggleMenu = useCallback(() => {
    setIsOpen((prevState) => !prevState);
    let menutoggle = document.querySelector(".toggle");
    if (menutoggle) {
      menutoggle.classList.toggle("active");
    }
  }, []);

  const closeNavbar = useCallback(() => {
    setIsOpen(false);
    let menutoggle = document.querySelector(".toggle");
    if (menutoggle) {
      menutoggle.classList.remove("active");
    }
  }, []);

  const openModalCloseNavbar = useCallback(() => {
    setIsOpen(false);
    setIsModalOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const body = document.body;
    body.style.overflow = isOpen || isModalOpen ? "hidden" : "visible";

    return () => {
      body.style.overflow = "visible";
    };
  }, [isOpen, isModalOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const isAboutPage = location.pathname === "/about";
  const isPortfolioPage = location.pathname === "/portfolio";

  const navbarClassNames = [
    "navbar",
    (isAboutPage || isPortfolioPage) && !isOpen && "transparent",
    isScrolled && !isAboutPage && !isPortfolioPage && "neumorphic",
  ]
    .filter(Boolean)
    .join(" ");

  // Function to handle link clicks within the same page
  const handleSamePageLinkClick = () => {
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };
    scrollToTop();
    closeNavbar();
  };

  const toggleGalleryDropdown = useCallback((event) => {
    event.stopPropagation(); // Prevent event bubbling
    setIsGalleryOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click target is the arrow icon or any other element that should open the dropdown
      const shouldOpenDropdown = event.target.closest(".gallery-ToggleBtnTxt");

      // If the click target is not the dropdown or the arrow icon, close the dropdown
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !shouldOpenDropdown
      ) {
        setIsGalleryOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <animated.nav style={fadeUp} className={navbarClassNames}>
      <div className="navbar-logo">
        <Link to="/" onClick={handleSamePageLinkClick}>
          <img src={Logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <ul className={`navbar-links ${isOpen ? "hidden" : ""}`}>
        <li>
          <Link to="/" onClick={handleSamePageLinkClick}>
            HOME
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={handleSamePageLinkClick}>
            ABOUT
          </Link>
        </li>
        <li>
          <Link to="/portfolio" onClick={handleSamePageLinkClick}>
            PORTFOLIO
          </Link>
        </li>
        <li>
          <Link
            to="https://www.ariesgroupglobal.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ARIES GROUP
          </Link>
        </li>
        <li>
          <button onClick={openModalCloseNavbar}>
            <p>CONTACT</p>
          </button>
        </li>
      </ul>
      <button onClick={toggleMenu}>
        <div className="toggle">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>

      {isModalOpen && (
        <Modal>
          <Contact closeModal={openModalCloseNavbar} />
        </Modal>
      )}

      <div className={`menu ${isOpen ? "open" : ""}`}>
        <div className="menu-container">
          <ul className="menu-container-mobileOnlyNav">
            <li>
              <Link to="/" onClick={handleSamePageLinkClick}>
                HOME
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={handleSamePageLinkClick}>
                ABOUT
              </Link>
            </li>
            <li>
              <Link to="/portfolio" onClick={handleSamePageLinkClick}>
                PORTFOLIO
              </Link>
            </li>
            <li>
              <Link
                to="https://www.ariesgroupglobal.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ARIES GROUP
              </Link>
            </li>
            <li>
              <button onClick={openModalCloseNavbar}>
                <p>CONTACT</p>
              </button>
            </li>
          </ul>
          <ul>
            <li className="gallery-dropdown" ref={dropdownRef}>
              <div
                className="gallery-ToggleBtnTxt"
                onClick={(event) => toggleGalleryDropdown(event)}
              >
                <button className="gallery-dropbtn">GALLERY</button>
                {isGalleryOpen ? (
                  <IoIosArrowUp
                    className="gallery-toggleArrow"
                    onClick={(event) => toggleGalleryDropdown(event)}
                  />
                ) : (
                  <IoIosArrowDown
                    className="gallery-toggleArrow"
                    onClick={(event) => toggleGalleryDropdown(event)}
                  />
                )}
              </div>

              {isGalleryOpen && (
                <animated.div
                  style={galleryDropdownContentAnimation}
                  className="gallery-dropdown-content"
                >
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/2024"
                    onClick={handleSamePageLinkClick}
                  >
                    Gallery 2024
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/2023"
                    onClick={handleSamePageLinkClick}
                  >
                    Gallery 2023
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/2022"
                    onClick={handleSamePageLinkClick}
                  >
                    Gallery 2022
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/2021"
                    onClick={handleSamePageLinkClick}
                  >
                    Gallery 2021
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/2020"
                    onClick={handleSamePageLinkClick}
                  >
                    Gallery 2020
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/2019"
                    onClick={handleSamePageLinkClick}
                  >
                    Gallery 2019
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/2018"
                    onClick={handleSamePageLinkClick}
                  >
                    Gallery 2018
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/2017"
                    onClick={handleSamePageLinkClick}
                  >
                    Gallery 2017
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/2016"
                    onClick={handleSamePageLinkClick}
                  >
                    Gallery 2016
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="https://docs.google.com/viewerng/viewer?url=http://www.sohanroy.com/images/Kailash+journey+of+a+Communist.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kailash Journey
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="https://photos.app.goo.gl/5sCH5YfQGGfMcHep2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Anukavyam
                  </Link>
                  <Link
                    className="gallery-dropdown-contentTxt"
                    to="/gallery/indywood"
                    onClick={handleSamePageLinkClick}
                  >
                    Indywood
                  </Link>
                </animated.div>
              )}
            </li>

            <li>
              <a
                href="https://kathakavyam.com/"
                target="_blank"
                rel="noreferrer"
                onClick={handleSamePageLinkClick}
              >
                STORIES
              </a>
            </li>
            <li>
              <Link to="/songs" onClick={handleSamePageLinkClick}>
                SONGS
              </Link>
            </li>
            <li>
              <Link to="/books" onClick={handleSamePageLinkClick}>
                BOOKS
              </Link>
            </li>
            <li>
              <Link to="/media" onClick={handleSamePageLinkClick}>
                MEDIA
              </Link>
            </li>
            <li>
              <Link to="/csr" onClick={handleSamePageLinkClick}>
                CSR
              </Link>
            </li>
            {/* <li>
              <Link to="/new-site/wolf-air-mask" onClick={handleSamePageLinkClick}>
                WOLF AIR MASK
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </animated.nav>
  );
};

export default Navbar;
