import React, { useContext, useRef, useState } from "react";
import { SongsContext } from "../../contexts/SongsContext";
import "./player.css";
// import {
//   MdOutlineSkipPrevious,
//   MdOutlinePlayCircle,
//   MdOutlinePauseCircle,
//   MdOutlineSkipNext,
// } from "react-icons/md";

const Player = () => {
  const {
    currentPlaylist,
    currentSong,
    isPlaying,
    audioRef,
    play,
    pause,
    next,
    previous,
  } = useContext(SongsContext);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const progressRef = useRef();

  const handlePlayPause = () => {
    if (isPlaying) {
      pause && pause();
    } else {
      play && play();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  const handleSeek = (e) => {
    if (!isSeeking) return;
    const seekTime =
      (e.nativeEvent.offsetX / progressRef.current.offsetWidth) * duration;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const handleSeekStart = () => {
    setIsSeeking(true);
  };

  const handleSeekEnd = () => {
    setIsSeeking(false);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="player-container">
      <div className="player-info">
        <p className="player-title">{currentSong ? currentSong.title : ""}</p>
      </div>
      <div className="progress-time-container">
        <div
          className="progress-container"
          ref={progressRef}
          onMouseDown={handleSeekStart}
          onMouseMove={handleSeek}
          onMouseUp={handleSeekEnd}
        >
          <div
            className="progress"
            style={{ width: `${(currentTime / duration) * 100}%` }}
          ></div>
        </div>
        <div className="time-info">
          <span>{formatTime(currentTime)}</span> /{" "}
          <span>{formatTime(duration)}</span>
        </div>
        <audio
          ref={audioRef}
          src={currentSong ? currentSong.url : ""}
          onTimeUpdate={handleTimeUpdate}
          onEnded={next}
        ></audio>
      </div>
      {/* <div className="player-controls">
        <button onClick={previous}>
          <MdOutlineSkipPrevious className="song-PlayIcon" />
        </button>
        {isPlaying ? (
          <button onClick={handlePlayPause}>
            <MdOutlinePauseCircle className="song-PlayIcon" />
          </button>
        ) : (
          <button onClick={handlePlayPause}>
            <MdOutlinePlayCircle className="song-PlayIcon" />
          </button>
        )}
        <button onClick={next}>
          <MdOutlineSkipNext className="song-PlayIcon" />
        </button>
      </div> */}
    </div>
  );
};

export default Player;
