import React, { createContext, useState, useEffect, useRef } from "react";

const SongsContext = createContext();

const SongsProvider = ({ children }) => {
  const [playlists, setPlaylists] = useState([]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlaylist, setCurrentPlaylist] = useState(null);
  const [currentSong, setCurrentSong] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    if (playlists.length > 0) {
      setCurrentPlaylist(playlists[currentSongIndex]);
      // Use the correct index within the current playlist
      setCurrentSong(playlists[currentSongIndex].songs[0]);
    }
  }, [currentSongIndex, playlists]);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        // Import playlists from JSON files or fetch from an API
        const playlist1 = await import("../data/songsData/aickBhi.json");
        const playlist2 = await import("../data/songsData/dam999.json");
        const playlist3 = await import("../data/songsData/hitsOfSR.json");
        const playlist4 = await import("../data/songsData/kkRoad.json");
        const playlist5 = await import("../data/songsData/muralika.json");
        const playlist6 = await import("../data/songsData/nombaracheppu.json");
        const playlist7 = await import("../data/songsData/urukkuchundan.json");
        const playlist8 = await import("../data/songsData/vacation.json");
        const playlist9 = await import("../data/songsData/viraham.json");

        // Merge playlists into a single array
        const allPlaylists = [
          playlist1.default[0],
          playlist2.default[0],
          playlist3.default[0],
          playlist4.default[0],
          playlist5.default[0],
          playlist6.default[0],
          playlist7.default[0],
          playlist8.default[0],
          playlist9.default[0],
        ];
        setPlaylists(allPlaylists);
      } catch (error) {
        console.error("Error fetching playlists:", error);
      }
    };

    fetchPlaylists();
  }, []);

  const play = (song) => {
    const playlistContainingSong = playlists.find((playlist) =>
      playlist.songs.some((s) => s.id === song.id)
    );

    if (playlistContainingSong) {
      setCurrentPlaylist(playlistContainingSong);
      setCurrentSong(song);

      // Pause and reset the current audio element
      audioRef.current.pause();
      audioRef.current.currentTime = 0;

      // Set the new source
      audioRef.current.src = song.url;

      // Listen for the loadedmetadata event
      audioRef.current.addEventListener("loadedmetadata", () => {
        // Once metadata is loaded, play the new song
        audioRef.current.play();
        setIsPlaying(true);
      });
    }
  };

  const pause = () => {
    if (audioRef.current && audioRef.current.pause) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      console.error(
        "audioRef.current is not initialized or does not have a pause function"
      );
    }
  };

  const next = () => {
    setCurrentSongIndex((prevIndex) =>
      prevIndex < playlists[currentSongIndex].songs.length - 1
        ? prevIndex + 1
        : 0
    );
    setIsPlaying(true); // Automatically start playing the next song
  };

  const previous = () => {
    setCurrentSongIndex((prevIndex) =>
      prevIndex > 0
        ? prevIndex - 1
        : playlists[currentSongIndex].songs.length - 1
    );
    setIsPlaying(true); // Automatically start playing the previous song
  };

  return (
    <SongsContext.Provider
      value={{
        playlists,
        currentSongIndex,
        isPlaying,
        currentPlaylist,
        currentSong,
        audioRef,
        play,
        pause,
        next,
        previous,
      }}
    >
      {children}
    </SongsContext.Provider>
  );
};

export { SongsContext, SongsProvider };
