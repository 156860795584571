import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Player } from "../../components";
import { SongsContext } from "../../contexts/SongsContext";
import "./playlist.css";
import PlaceHolderImg from "../../assets/images/songs/dam999.webp";
import { MdOutlinePlayCircle, MdOutlinePauseCircle } from "react-icons/md";

const Playlist = () => {
  const { playlists, play, pause } = useContext(SongsContext);
  const { id } = useParams();
  const [currentSong, setCurrentSong] = useState(null); // State to track current song
  const [isPlaying, setIsPlaying] = useState(false); // State to track if a song is playing

  // Find the current playlist based on the id parameter
  const playlist = playlists.find((playlist) => playlist.id === parseInt(id));

  // Function to handle play/pause toggle
  const handlePlayPause = (song) => {
    if (currentSong === song) {
      // If the same song is clicked again
      if (isPlaying) {
        pause(); // Pause the song
        setIsPlaying(false); // Update state
      } else {
        play(song); // Play the song
        setIsPlaying(true); // Update state
      }
    } else {
      // If a different song is clicked
      play(song); // Play the new song
      setCurrentSong(song); // Update current song state
      setIsPlaying(true); // Update state
    }
  };

  return (
    <div className="playlist-container">
      {playlist && (
        <>
          <div className="song-img-container">
            <div>
              <img src={PlaceHolderImg} alt={playlist.name} />
              <h4 className="playlistPage-title">{playlist.name}</h4>
            </div>

            <ul>
              {playlist.songs.map((song) => (
                <div className="song-container" key={song.title}>
                  <li>
                    <div>
                      <div className="song-id">
                        <p>{song.id}</p>
                      </div>
                      <p>{song.title}</p>
                    </div>

                    <button onClick={() => handlePlayPause(song)}>
                      {currentSong === song && isPlaying ? (
                        <MdOutlinePauseCircle className="song-PlayIcon" />
                      ) : (
                        <MdOutlinePlayCircle className="song-PlayIcon" />
                      )}
                    </button>
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </>
      )}
      <Player />}
    </div>
  );
};

export default Playlist;
