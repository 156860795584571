import { useState } from "react";
import "./booksCard.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards, Autoplay } from "swiper/modules";
import React from "react";

const BooksCard = ({ data }) => {
  const [booksLoaded, setBooksLoaded] = useState(false);
  const handleImageLoad = () => {
    setBooksLoaded(true);
  };
  return (
    <>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards, Autoplay]}
        initialSlide={0}
        loop={true}
        loopAdditionalSlides={4}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
        {data.map((book, index) => (
          <SwiperSlide key={index}>
            <img
              src={booksLoaded ? book.imgSource : book.imgS}
              alt={`Book ${index + 1}`}
              className={`book-img ${booksLoaded ? "" : "placeholder-image"}`}
              onLoad={handleImageLoad}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default BooksCard;
