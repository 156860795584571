// ArrowButton.js

import React from "react";
import PropTypes from "prop-types";
import "./arrowButton.css";
import { Link } from "react-router-dom";

import { GoArrowRight, GoArrowLeft } from "react-icons/go";

const ArrowButton = ({
  label,
  arrowDirection,
  arrowSize,
  labelSize,
  arrowColor,
  textColor,
  fontFamily,
  to,
}) => {
  const arrowStyle = {
    fontSize: arrowSize,
    color: arrowColor,
    fontFamily: fontFamily,
  };
  const labelStyle = {
    fontSize: labelSize,
    color: textColor,
    fontFamily: fontFamily,
  };

  return (
    <Link to={to} className="arrow-button">
      {arrowDirection === "left" && (
        <GoArrowLeft className="arrow-left" style={arrowStyle} />
      )}
      <span className="button-label title-normal" style={labelStyle}>
        {label}
      </span>
      {arrowDirection === "right" && (
        <GoArrowRight className="arrow-right" style={arrowStyle} />
      )}
    </Link>
  );
};

ArrowButton.propTypes = {
  label: PropTypes.string.isRequired,
  arrowDirection: PropTypes.oneOf(["left", "right"]),
  arrowSize: PropTypes.string,
  labelSize: PropTypes.string,
  arrowColor: PropTypes.string,
  textColor: PropTypes.string,
  fontFamily: PropTypes.string,
  to: PropTypes.string.isRequired,
};

ArrowButton.defaultProps = {
  arrowDirection: "right",
  arrowSize: "65px",
  labelSize: "55px",
  arrowColor: "var(--color-gold)",
  textColor: "var(--color-gold)",
  fontFamily: "var(--font-sub)", // Default font family variable
};

export default ArrowButton;
