import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "./app.css";
import { MetaTagsManager } from "./components";

import { Loader, Modal, ScrollToTop } from "./components";
import { Navbar, Footer } from "./containers/Common";

// Lazy load page components
const Home = React.lazy(() => import("./pages/Home/Home"));
const About = React.lazy(() => import("./pages/About/About"));
const Media = React.lazy(() => import("./pages/Media/Media"));
const MediaRegional = React.lazy(() =>
  import("./pages/MediaRegional/MediaRegional")
);
const Csr = React.lazy(() => import("./pages/Csr/Csr"));
const Portfolio = React.lazy(() => import("./pages/Portfolio/Portfolio"));
const Books = React.lazy(() => import("./pages/Books/Books"));
const Songs = React.lazy(() => import("./pages/Songs/Songs"));
const PageNotFound = React.lazy(() =>
  import("./pages/PageNotFound/PageNotFound")
);
const Playlist = React.lazy(() => import("./components/Playlist/Playlist"));

const Gallery2024 = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/Gallery2024/Gallery2024")
);

const Gallery2023 = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/Gallery2023/Gallery2023")
);
const Gallery2022 = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/Gallery2022/Gallery2022")
);
const Gallery2021 = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/Gallery2021/Gallery2021")
);
const Gallery2020 = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/Gallery2020/Gallery2020")
);
const Gallery2019 = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/Gallery2019/Gallery2019")
);
const Gallery2018 = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/Gallery2018/Gallery2018")
);
const Gallery2017 = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/Gallery2017/Gallery2017")
);
const Gallery2016 = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/Gallery2016/Gallery2016")
);
const GalleryIndy = React.lazy(() =>
  import("./pages/Gallery/GalleryPages/GalleryIndy/GalleryIndy")
);
// const Kailash = React.lazy(() => import("./pages/Kailash/Kailash"));

const PageNotFoundEl = () => {
  return (
    <Modal>
      <PageNotFound />
    </Modal>
  );
};

const App = () => {
  return (
    <MetaTagsManager>
      <>
        <Navbar />
        <Suspense
          fallback={
            <Modal>
              <Loader />
            </Modal>
          }
        >
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/about" element={<About />} />
            <Route path="/media" element={<Media />} />
            <Route path="/media/regional" element={<MediaRegional />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/csr" element={<Csr />} />
            <Route path="/books" element={<Books />} />
            <Route path="/songs" element={<Songs />} />
            <Route path="/songs/playlist/:id" element={<Playlist />} />
            <Route path="/gallery/2024" element={<Gallery2024 />} exact />
            <Route path="/gallery/2023" element={<Gallery2023 />} exact />
            <Route path="/gallery/2022" element={<Gallery2022 />} exact />
            <Route path="/gallery/2021" element={<Gallery2021 />} exact />
            <Route path="/gallery/2020" element={<Gallery2020 />} exact />
            <Route path="/gallery/2019" element={<Gallery2019 />} exact />
            <Route path="/gallery/2018" element={<Gallery2018 />} exact />
            <Route path="/gallery/2017" element={<Gallery2017 />} exact />
            <Route path="/gallery/2016" element={<Gallery2016 />} exact />
            <Route path="/gallery/indywood" element={<GalleryIndy />} exact />
            {/* <Route
              path="/gallery/kailash-journey"
              element={<Kailash />}
              exact
            /> */}
            <Route path="*" element={<PageNotFoundEl />} />
          </Routes>
        </Suspense>
        <ScrollToTop />
        <Footer />
      </>
    </MetaTagsManager>
  );
};

export default App;
